import * as React from "react"


 import Layout from "../components/layout"
 import Footer from '../components/footer'


 const ErrorPage = () => (

    <Layout>

     <div>

        <h1>404 - Page could not be found</h1> 

     </div>
     <Footer></Footer>
   </Layout>

 )


 export default ErrorPage
